import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'

import Layout from '../components/layout'
import SEO from "../components/seo"


const Privacy = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const html = data.privacy.contentLongText.childMarkdownRemark.html

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title="Culture Yard Internet Privacy & Cookies Policy" description={data.privacy.description.description} />
      <h1 tw="md:px-40 text-center">Internet Privacy & Cookies Policy</h1>
      <section tw="md:px-40" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}


export default Privacy


export const data = graphql`
   query {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    privacy:contentfulStaticPage(title: {eq: "Privacy Policy"}) {
      title
      description {
        description
      }
      contentLongText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
